import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";
import config from "../../functions/config";
import { useNavigate } from "react-router-dom";
import "../styles/style.css";

function SignIn() {
    useEffect(() => {
        document.body.style.backgroundColor = 'orange';
        return () => {
        document.body.style.backgroundColor = ''; 
        };
    }, []);
  
    const [logUsername, setLogUsername] = useState("");
    const [logPassword, setLogPassword] = useState("");

    const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    const loginData = {
      username: logUsername,
      password: logPassword,
    };

    axios
      .post(`${config.base_url}/LogIn/`, loginData)
      .then((res) => {
        if (res.data.status) {
          Cookies.set("user_id", res.data.user);
          Cookies.set("access", res.data.access);
          Cookies.set("refresh", res.data.refresh);
          Cookies.set("role", res.data.role);

          if (res.data.redirect != "") {
            navigate("/" + res.data.redirect);
          }
        } else if (!res.data.status && res.data.redirect != "") {
          Cookies.set("user_id", res.data.user);
          Swal.fire({
            icon: "error",
            title: `${res.data.message}`,
          });
          navigate("/" + res.data.redirect);
        }
      })
      .catch((err) => {
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
          if (err.response.data.redirect && err.response.data.redirect != "") {
            navigate("/" + err.response.data.redirect);
          }
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  return (
    <div className="main" style={{ backgroundColor: 'rgba(225, 155, 24, 0.563)' }}>
      <style>
        {`
          /* Placeholder colors */
          #firstname::placeholder,
          #lastname::placeholder,
          #email::placeholder,
          #username::placeholder,
          #phoneno::placeholder,
          #pass::placeholder,
          #re_pass::placeholder,
          #gsttype::placeholder,
          #gstno::placeholder {
            color: black;
          }
        `}
      </style>
    <section className="sign-in">
      <div className="container1">
        <div className="signin-content">
            <div className="signin-image">

            {/* <div className="w-100 d-flex justify-content-start">
              {messages.map((message, index) => (
                <div key={index} style={{ backgroundColor: 'rgba(255, 0, 0, 0.5)', color: 'white', textShadow: '2px 2px 4px #000000', padding: '10px', borderRadius: '10px' }}>
                  {message}
                </div>
              ))}
            </div> */}
                
            <img
                  src={require("../../assets/images/faces/signin-image.jpg")}
                  alt="user"
                />
            </div>
            <div className="signin-form">
            <h2 className="form-title">Sign In</h2>
            <form className="login-form" id="login-form" onSubmit={handleLogin} encType="multipart/form-data">
                <div className="form-group"> 
                <label htmlFor="username"><i className="zmdi zmdi-account material-icons-name" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="username" 
                  value={logUsername}
                  onChange={(e) => setLogUsername(e.target.value)}
                  placeholder="Enter User Name" />
                </div>
                <div className="form-group">
                <label htmlFor="your_pass"><i className="zmdi zmdi-lock" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="password" 
                  name="pass" 
                  value={logPassword}
                  onChange={(e) => setLogPassword(e.target.value)}
                  placeholder=" Enter Password" />
                </div>
                <div className="form-group">
                </div>
                <div className="form-group form-button">
                <input type="submit" name="signin" id="signin" className="form-submit" defaultValue="Log in" />
                </div>
            </form>
            </div>
        </div>
      </div>
    </section>

    </div>
  );
}
export default SignIn;
