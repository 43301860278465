import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../functions/config";
import "../styles/style.css";
import { useNavigate } from "react-router-dom";

function CompanyReg2() {
  useEffect(() => {
    document.body.style.backgroundColor = 'orange';
    return () => {
      document.body.style.backgroundColor = ''; 
    };
  }, []);

  const navigate = useNavigate();
  const ID = Cookies.get("user_id");

  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [panNo, setPanNo] = useState("");
  const [gstType, setGstType] = useState('');
  const [gstNo, setGstNo] = useState("");
  const [showGstNo, setShowGstNo] = useState(false);

  // Toggle GST Number field visibility
  const toggleGSTNumberField = (event) => {
    const selectedGstType = event.target.value;
    setGstType(selectedGstType);
    if (selectedGstType === 'Registered Business - Regular' || selectedGstType === 'Registered Business - Composition') {
      setShowGstNo(true);
    } else {
      setShowGstNo(false);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('Id', ID);
    formData.append('company_name', companyName);
    formData.append('address', companyAddress);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('country', country);
    formData.append('pincode', pincode);
    formData.append('pan_number', panNo);
    formData.append('gst_type', gstType);
    formData.append('gst_no', gstNo);
   
    axios
      .put(`${config.base_url}/CompanyReg2_action2/`, formData)
      .then((res) => {
        if (res.data.status) {
          navigate("/login");
        }

      })
      .catch((err) => {
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }

  return (
    <div className="main" style={{ backgroundColor: 'rgba(225, 155, 24, 0.563)', marginTop: '60px' }}>
        <style>
        {`
          .custom-select {
            position: relative;
            display: inline-block;
          }
    
          .custom-select select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border: none;
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            background-color: transparent;
          }
    
          .select-arrow {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            pointer-events: none;
            color: #000;
          }
    
          .underline {
            position: relative;
            width: 300px;
            border-bottom: 1px solid #000;
            margin-bottom: 5px;
          }
          
          /* Placeholder colors */
          #address::placeholder,
          #companyname::placeholder,
          #email::placeholder,
          #city::placeholder,
          #country::placeholder,
          #pincode::placeholder,
          #pannumber::placeholder,
          #gsttype::placeholder,
          #gstno::placeholder {
            color: black;
          }
        `}
      </style>
       <section className="signup">
      <div className="container1">
        <br></br>
        <h2 className="form-title" >Register Company</h2>
        <div className="signup-content">
          <div className="signup-form" style={{ marginTop: '-50px' }}>
            {/* If there are messages (this will depend on how you handle form errors in React) */}
            {/* <div className="w-100 d-flex justify-content-start">
              {messages.map((message, index) => (
                <div key={index} style={{ backgroundColor: 'rgba(255, 0, 0, 0.5)', color: 'white', textShadow: '2px 2px 4px #000000', padding: '10px', borderRadius: '10px' }}>
                  {message}
                </div>
              ))}
            </div> */}
            <form className="register-form" autocomplete="off" id="register-form" onSubmit={handleSubmit} encType="multipart/form-data">
             <div className="form-group">
                <label for="companyname"><i className="zmdi zmdi-account" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="companyname" 
                  placeholder="Enter Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required />
              </div>

              <div className="form-group">
                <label for="email"><i className="zmdi zmdi-email" style={{ fontSize: '18px' }}></i></label>
                <input type="email" name="email" id="email" placeholder="Enter Email (same as Previous page)" required />
              </div>

              <div className="form-group">
                <label for="address"><i className="zmdi zmdi-account" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="address"
                  value={companyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  placeholder="Enter Address" />
              </div>

              <div className="form-group">
                <label for="city"><i className="zmdi zmdi-city" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="city" 
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Enter City" />
              </div>

              <div className="form-group">
                <label for="state"><i className="zmdi zmdi-pin-drop" style={{ fontSize: '18px' }}></i></label>
                <div className="select">
                    <select className="form-control " id="state"
                      onChange={(e) => setState(e.target.value)}
                      style={{ paddingLeft: '30px' }} 
                      name="state">
                    <option value selected>Choose State</option>
                    <option value="Andaman and Nicobar Islads">Andaman and Nicobar Islads</option>
                    <option value="Andhra Predhesh">Andhra Predhesh</option>
                    <option value="Arunachal Predesh">Arunachal Predesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                    <option value="Damn anad Diu">Damn anad Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Predesh">Himachal Predesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Ladakh">Ladakh</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Madhya Predesh">Madhya Predesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Predesh">Uttar Predesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                    <option value="Other Territory">Other Territory</option>
                    </select>
                    <div className="select-arrow">▼</div> 
                    <div className="underline" />
                </div>
              </div>


              <div className="form-group">
                <label for="country"><i className="zmdi zmdi-flag" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="country"  
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  placeholder="Enter Country" />
              </div>

              <div className="form-group">
                <label for="pincode"><i className="zmdi zmdi-pin" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="number" 
                  name="pincode" 
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  placeholder="Enter Pincode" />
              </div>

              <div className="form-group">
                <label for="pannumber"><i className="zmdi zmdi-card" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="pannumber" 
                  pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}" 
                  value={panNo}
                  onChange={(e) => setPanNo(e.target.value)}
                  placeholder="PAN Number: ABCDE1234A" />
              </div>

              <div className="form-group select">
                  <label htmlFor="gsttype">
                    <i className="zmdi zmdi-case-check" style={{ fontSize: '18px' }}></i>
                  </label>
                  <select className="form-control" 
                      name="gsttype" id="gsttype" 
                      value={gstType}
                      style={{ paddingLeft: '30px' }} 
                      // onChange={(e) => handleGstType(e.target.value)}
                      onChange={toggleGSTNumberField}>
                    <option selected disabled value="">Select GST Type</option>
                    <option value="Registered Business - Regular">Registered Business - Regular (Business registered under GST)</option>
                    <option value="Registered Business - Composition">Registered Business - Composition (Business registered under composition scheme)</option>
                    <option value="Unregistered Business">Unregistered Business</option>
                    <option value="Overseas">Overseas</option>
                    <option value="Consumer">Consumer</option>
                  </select>
                  <span className="select-arrow">▼</span>
                  <div className="underline" />
              </div>
              {showGstNo && (
                <div className="form-group">
                  <label for="gstno"><i className="zmdi zmdi-file" style={{ fontSize: '18px' }}></i></label>
                  <input 
                    type="text" className="gstno" 
                    name="gstno"  
                    value={gstNo}
                    onChange={(e) => setGstNo(e.target.value)}
                    pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$" 
                    placeholder="GST Number: 11AAAAA1111A1ZA" />
                </div>
              )}
              <div className="form-group form-button">
                <input type="submit" name="signup" id="signup" className="form-submit" value="Register" />
              </div>
            </form>
          </div>
          <div className="signup-image">
            <img
              src={require("../../assets/images/faces/signup-image.jpg")}
              alt="user"
            />
            <Link to="/login" className="signup-image-link">I am already a member</Link>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}

export default CompanyReg2;
