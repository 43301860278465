import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Trans, useSSR } from "react-i18next";
import "./styles/Navbar.css";
import "bootstrap/dist/css/bootstrap.css";
import Cookies from "js-cookie";
import config from "../../functions/config";
import axios from "axios";

const Navbar = () => {
  const navigate = useNavigate();
  const [showFullLogo, setShowFullLogo] = useState(true);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
    // setShowFullLogo(!showFullLogo)
  };

  function toggleSidebar() {
    document.body.classList.toggle("sidebar-icon-only");
    setShowFullLogo(!showFullLogo);
  }

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }
  const ID = Cookies.get("user_id");
  
  const [loginName, setLoginName] = useState("");
  const [loginImage, setLoginImage] = useState("");

  const getLogDetails = () => {
    axios
      .get(`${config.base_url}/user/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const details = res.data.data;
          var logImg = null;
          if (details.image) {
            logImg = `${config.base_url}${details.image}`;
          }
          setLoginImage(logImg);
          setLoginName(details.name);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  useEffect(() => {
    getLogDetails();
  }, []);

  function formatTimeInput(timeString) {
    let [hours, minutes] = timeString.split(":").slice(0, 2);

    hours = parseInt(hours, 10);

    let meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Handle midnight (0) and noon (12)

    hours = String(hours).padStart(2, "0");
    minutes = String(minutes).padStart(2, "0");

    return `${hours}:${minutes} ${meridiem}`;
  }

  const toggleRightSidebar = () => {
    document.querySelector(".right-sidebar").classList.toggle("open");
  };

  function handleLogout() {
    Cookies.remove("role");
    Cookies.remove("user_id");
    Cookies.remove("access");
    Cookies.remove("refresh");
    navigate("/");
  }

  return (
    <nav className="admin-nav navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row justify-content-between">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        {showFullLogo ? (
          <Link
            className="navbar-brand brand-logo pl-4 d-lg-block d-none"
            to="/company_home"
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={require("../../assets/images/faces/logo3.png")} alt="logo" width="35" height="35" />
                <h5 className="text-muted" style={{ marginLeft: '10px' }}>
                    MultiUser Billing <br /> Software In India
                </h5>
            </div>

          </Link>
        ) : (
          <Link
            className="navbar-brand brand-logo pl-4 d-lg-block d-none"
            to="/company_home"
          >
            <img src={require("../../assets/images/faces/logo3.png")} alt="logo"  width="35" height="35" />
          </Link>
        )}
        <Link
          // className="navbar-brand brand-logo-mini d-block d-lg-none pl-4"
          className="navbar-brand brand-logo-mini d-block d-lg-none pl-4"
          to="/company_home"
        >
          <img src={require("../../assets/images/faces/logo3.png")} alt="logo"  width="35" height="35" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-between">
        <div className="left d-flex align-items-center justify-content-start">
          <button
            className="navbar-toggler navbar-toggler align-self-center  d-none d-lg-block"
            type="button"
            onClick={toggleSidebar}
          >
            <span className="mdi mdi-menu"></span>
          </button>
          <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  {/* <i className="input-group-text border-0 mdi mdi-magnify"></i> */}
                <span className="search_icon p-3 c-pointer" data-toggle="dropdown" style={{ fontSize: '24px' }}>
                    <i className="mdi mdi-magnify"></i>
                </span>

                </div>
                {/* <input
                  type="text"
                  className="form-control bg-transparent border-0"
                  placeholder="Search.."
                /> */}
                <div className="search_bar">
                  <input id="searchInput" className="form-control" type="search" placeholder="Search" aria-label="Search" />
                  <button className="search-button" onclick="searchModule()">Search</button>
                </div>

              </div>
            </form>
          </div>
        </div>
        <div className="right pr-3 d-flex">
          <ul className="navbar-nav navbar-nav-right d-flex flex-row">
            
            <li className="nav-item nav-profile ml-3 mr-2">
              <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link d-flex flex-column align-items-center navDropButtons position-relative custom-dropdown-toggle">
                <div className="nav-profile-img">
                  {loginImage && loginImage !== "" ? (
                    <img src={loginImage} alt="user" />
                  ) : (
                    <img
                      src={require("../../assets/images/faces/default.png")}
                      alt="user"
                    />
                  )}
                </div>

                <div className="nav-profile-text mt-2">
                  <p className="mb-1 text-grey font-weight-bold text-center">
                    <Trans>{loginName != null ? loginName : "Company"}</Trans>
                  </p>
                </div>
              </Dropdown.Toggle>
                <Dropdown.Menu className="navbar-dropdown position-absolute">
                  <Dropdown.Item onClick={() => navigate("/company_profile")}>
                    <i className="mdi mdi-account mr-2 text-success"></i>
                    <Trans>Profile</Trans>
                  </Dropdown.Item>
                 
                  <Dropdown.Item onClick={handleLogout}>
                    <i className="mdi mdi-logout mr-2 text-danger"></i>
                    <Trans>Logout</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center px-0"
            type="button"
            onClick={toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
