import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function Index() {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdown2Visible, setDropdown2Visible] = useState(true);

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleClickOrFocus = () => {
    setDropdown2Visible(!dropdown2Visible);
  };

  const handleClickOrFocusOut = () => {
    if (dropdownVisible) {
      setDropdownVisible(false);
    }
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  
  return (
    <div id="indexPage"> 
    <style>
        {`
        @media (max-width: 768px) {
        .navbar-nav {
          text-align: center;
        }

        .navbar-nav .nav-item {
          padding: 0.5rem 0;
        }

        .footer-item {
          text-align: center;
          margin-bottom: 20px;
        }

        .footer-item ul {
          padding: 0;
          list-style: none;
        }

        .main-banner .title2 {
          font-size: 1.8rem; /* Decrease the banner text size */
        }

        .partners .container {
          text-align: center;
        }

        /* Ensure buttons and links are easy to tap */
        .filled-button {
          width: 100%;
          margin-top: 10px;
        }

        .navbar-toggler {
          border: none;
        }

        /* Make the footer icons easier to tap */
        .footer-icons ul {
          display: flex;
          justify-content: center;
          padding: 0;
        }

        .footer-icons ul li {
          margin: 0 5px;
        }
      }

      @media (max-width: 576px) {
        .footer-item {
          margin-bottom: 15px;
        }

        .footer-content h4 {
          font-size: 1.2rem;
        }

        .footer-contacts p {
          font-size: 0.9rem;
        }

        .footer-icons ul li {
          margin: 0 3px;
        }
      }

        `}
    </style> 
      <header className="">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="#">
              <img src={require("../../assets/images/faces/logo3.png")} alt="logo"  width="35" height="35" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
         
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav mx-auto pb- ">
              
                <li className="nav-item">
                  <a className="nav-link current" href="#top">
                    Home
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#about">
                    About us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#services">
                    Service
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contactus">
                    contacts
                  </a>
                </li>

                <li className="nav-item d-inline-block d-lg-none">
                  <a className="nav-link" onClick={() => navigate("/company_registration")}>
                    Company Registration
                  </a>
                </li>

                <li className="nav-item d-inline-block d-lg-none mb-2">
                  <a className="nav-link" onClick={() => navigate("/staff_registration")}>
                    Staff Registration
                  </a>
                </li>

                <li class="nav-item dropdown d-none d-lg-inline-block">
                  <button
                    class="btn btn-secondary nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{backgroundColor: 'orange', width: 120,color: 'white',}}
                  >
                    REGISTER
                  </button>
                  <div
                    class="dropdown-menu show_reg_options"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a onClick={() => navigate("/company_registration")}>
                        Company Registration
                      </a>
                    </li>
                    <li>
                      <a onClick={() => navigate("/staff_registration")}>
                        Staff Registration
                      </a>
                    </li>
                  </div>
                </li>
                <a
                  onClick={() => navigate("/login")}
                  style={{
                    width: "fit-content",
                    height: "fit-content",
                    margin: "0px 15px",
                  }}
                  className="filled-button mx-auto"
                >
                  LOGIN
                </a>
              </ul>
            </div>
            
          </div>
        </nav>
      </header>
      {/* Page Content */}
      {/* Banner Starts Here */}
      
      <div className="main-banner header-text" id="top">
        <div className="Modern-Slider">
          <div className="item item-1">
            <div className="img-fill">
              <div className="text-content text-center">
                <h2 className="title2">
                Best multiuser billing 
                <br />
                software in india
                </h2>
                <div className="layer-1-3">
                <a href="" className="ready-btn left-btn">Get started</a>
                <div className="video-content">
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="partners">
        <div className="container">
          
        </div>
      </div>
      {/* Footer Starts Here */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-3 col-xs-12 footer-item">
            <div className="footer-content">
            <div className="footer-head">
            <h4>Services Link</h4>
              <ul className="footer-list">
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">About us</Link></li>
                <li><Link to="#">Contact us</Link></li>
                <li><Link to="#">Services</Link></li>
                <li><Link to="#">Privacy</Link></li>
                <li><Link to="#">Terms & Condition</Link></li>
              </ul>
            </div>
            </div>
            </div>
            <div className="col-md-1 footer-item">
              
            </div>
            <div className="col-md-3 col-sm-4 col-xs-12 footer-item">
            <div className="footer-content last-content">
            <div className="footer-head">
              <h4>Information</h4>
              <div className="footer-contacts">
                <p><span>Tel :</span> 9983898799</p>
                <p><span>Email :</span> billsoftware.com</p>
                <p><span>Location :</span> Carnival 1, Infopark, Kakkanad</p>
              </div>
              <div className="footer-icons">
                <ul>
                  <li><Link to="#"><i className="fa fa-facebook"></i></Link></li>
                  <li><Link to="#"><i className="fa fa-twitter"></i></Link></li>
                  <li><Link to="#"><i className="fa fa-google"></i></Link></li>
                  <li><Link to="#"><i className="fa fa-pinterest"></i></Link></li>
                  <li><Link to="#"><i className="fa fa-instagram"></i></Link></li>
                </ul>
              </div>
            </div>
          </div>
            </div>
           
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Index;
