import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./components/index/Index";
import SignIn from "./components/SignIn/SignIn";
import CompanyReg from "./components/company/CompanyReg";
import CompanyReg2 from "./components/company/CompanyReg2";
import StaffReg from "./components/staff/StaffReg";

import CompanyPrivateRoutes from "./components/routes/CompanyPrivateRoutes";
import CompanyStaffPrivateRoutes from "./components/routes/CompanyStaffPrivateRoutes";
import CompanyHome from "./components/company/CompanyHome";
import CompanyProfile from "./components/company/CompanyProfile";
import EditCompanyProfile from "./components/company/EditCompanyProfile";
import EditStaffProfile from "./components/staff/EditStaffProfile";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/login" element={<SignIn />}></Route>
          <Route path="/company_registration" element={<CompanyReg />}></Route>
          <Route path="/company_registration2" element={<CompanyReg2 />}></Route>
          <Route path="/staff_registration" element={<StaffReg />}></Route>

          <Route element={<CompanyPrivateRoutes />}>
            <Route path="/edit_company_profile" element={<EditCompanyProfile />}></Route>
          </Route>
          
          <Route element={<CompanyStaffPrivateRoutes />}>
            <Route path="/company_home" element={<CompanyHome />}></Route>
            <Route path="/company_profile" element={<CompanyProfile />}></Route>
            <Route path="/edit_staff_profile" element={<EditStaffProfile />}></Route>

            
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
