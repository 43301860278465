import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/AdminStyles.css"
import "./styles/Layout.css";


function CompanyHome() {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("COMPANY NAME");

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }
  const ID = Cookies.get("user_id");
  
  const [loginName, setLoginName] = useState("");
  const [loginImage, setLoginImage] = useState("");

  const getLogDetails = () => {
    axios
      .get(`${config.base_url}/user/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const details = res.data.data;
          var logImg = null;
          if (details.image) {
            logImg = `${config.base_url}${details.image}`;
          }
          setLoginImage(logImg);
          setLoginName(details.name);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  useEffect(() => {
    getLogDetails();
  }, []);
  
  const [panelWidth, setPanelWidth] = useState(window.innerWidth > 768 ? "90%" : "100%");

  useEffect(() => {
    const handleResize = () => setPanelWidth(window.innerWidth > 768 ? "90%" : "100%");
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        {/* <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961" }}
        > */}
         <div
      className="main-panel px-3 py-2"
      style={{ background: "#a9a9a961", width: panelWidth }}
    >
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh"  }}>
              <div className="card radius-15" >
                <div className="card-body" >
                  <div className="card-title">
                    <form method="post">
                      <div className="row w-100">
                        <div className="col"></div>
                        <div className="col">
                          <center className="w-100">
                            <label
                              style={{
                                textAlign: "center",
                                fontSize: window.innerWidth > 768 ? "30px" : "20px", 
                                textTransform: "uppercase",
                                fontWeight: "bold", 
                              }}
                            >
                              {loginName != null ? loginName : "Company"}
                            </label>
                          </center>

                        </div>
                        <div className="col"></div>
                      </div>
                    </form>
                    <hr />
                  </div>
                </div>
              </div>
              
              <button
                style={{ visibility: "hidden" }}
                id="modalBtn"
                data-toggle="modal"
                data-target="#myModal"
              ></button>
            </div>

            
          </div>
        </div>

      </div>
    </div>
  );
}

export default CompanyHome;




 

