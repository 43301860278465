import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import config from "../../functions/config";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";
import "../styles/style.css";
import { useNavigate } from "react-router-dom";
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';

function CompanyReg() {
  useEffect(() => {
    document.body.style.backgroundColor = 'orange';
    return () => {
      document.body.style.backgroundColor = ''; 
    };
  }, []);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordMatch(e.target.value, confirmPassword);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordMatch(password, e.target.value);
  };
  const checkPasswordMatch = (pass, confirmPass) => {
    if (pass === confirmPass) {
      setMessage('Password is matching');
    } else {
      setMessage('Password is not matching');
    }
  };
  

  function validate() {
    var pwd = document.getElementById("pwd").value;
    var cnfpwd = document.getElementById("cnfpwd").value;

    if (pwd.length < 8 || pwd.length > 18) {
      alert("Password length is invalid");
      return false;
    }
    if (pwd != cnfpwd) {
      alert("password and confirm password does not match");
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = validate();
    if (valid) {
      const data = new FormData();
        data.append('first_name', firstName);
        data.append('last_name', lastName);
        data.append('email', email);
        data.append('username', username);
        data.append('contact', phoneNumber); 
        data.append('password', password);
        if (file) {
          data.append('profile_pic', file); 
        }

      axios
        .post(`${config.base_url}/companyReg_action/`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          if (res.data.status) {
            Cookies.set("user_id", res.data.data.user);
            navigate("/company_registration2");
          }
        })
        .catch((err) => {
          console.log("ERROR==", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  return (
    <div className="main" style={{ backgroundColor: 'rgba(225, 155, 24, 0.563)', marginTop: '60px' }}>
      <style>
        {`
          /* Placeholder colors */
          #firstname::placeholder,
          #lastname::placeholder,
          #email::placeholder,
          #username::placeholder,
          #phoneno::placeholder,
          #pass::placeholder,
          #re_pass::placeholder,
          #gsttype::placeholder,
          #gstno::placeholder {
            color: black;
          }
        `}
      </style>
       <section className="signup">
      <div className="container1">
        <br></br>
        <h2 className="form-title">Register User</h2>
        <div className="signup-content">
          <div className="signup-form" style={{ marginTop: '-50px' }}>
            {/* If there are messages (this will depend on how you handle form errors in React) */}
            {/* <div className="w-100 d-flex justify-content-start">
              {messages.map((message, index) => (
                <div key={index} style={{ backgroundColor: 'rgba(255, 0, 0, 0.5)', color: 'white', textShadow: '2px 2px 4px #000000', padding: '10px', borderRadius: '10px' }}>
                  {message}
                </div>
              ))}
            </div> */}
            <form className="register-form" autocomplete="off" id="register-form" onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="form-group">
                <label for="firstname"><i className="zmdi zmdi-account" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="first_name"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}  
                  required />
              </div>

              <div className="form-group">
                <label for="lastname"><i className="zmdi zmdi-account" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="last_name" 
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required />
              </div>

              <div className="form-group">
                <label for="email"><i className="zmdi zmdi-email"style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="email" 
                  name="email" 
                  placeholder="Enter Email" 
                  pattern=".+@gmail\.com" 
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required />
              </div>

              <div className="form-group">
                <label for="username"><i className="zmdi zmdi-account" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="username"
                  placeholder="Enter User Name"
                  id="user" 
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required />
              </div>

              <div className="form-group">
                <label for="phoneno"><i className="zmdi zmdi-phone" style={{ fontSize: '18px' }}></i></label>
                <input 
                  type="text" 
                  name="phoneno" 
                  placeholder="Enter Phone Number" 
                  pattern="\d{10}" 
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required />
              </div>

              <div className="form-group">
                <label htmlFor="pass"><i className="zmdi zmdi-lock" style={{ fontSize: '18px' }}></i></label>
                <input type="password" name="pass"  placeholder="Enter Password"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  value={password}
                  onInput={handlePasswordChange}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  id="pwd"
                />
              </div>
              
              <p className="text-primary" style={{fontSize: 12, marginTop: '-10px', color: 'rgba(32, 32, 228, 0.807)'}}>*password must be at least 8 characters long and include uppercase , lowercase, special characters and numbers.</p>
              
              <div className="form-group" style={{ marginTop: 15 }}>
                <label htmlFor="re-pass"><i className="zmdi zmdi-lock-outline" style={{ fontSize: '18px' }}></i></label>
                <input type="password" name="re_pass" id="cnfpwd" placeholder="Repeat your password"
                  value={confirmPassword}
                  onInput={handleConfirmPasswordChange}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>

              <div id="message" style={{ color: password === confirmPassword ? 'green' : 'red', fontSize: '12px', marginTop: '2px' }}>
                {message}
              </div>
              <div>
              <div className="form-group" style={{marginTop: 15}}>
                <label htmlFor="re-pass"><i className="zmdi zmdi-file" style={{ fontSize: '18px' }}></i></label>
                <input 
                type="file" 
                name="image" 
                accept="image/*" 
                style={{ paddingLeft: '30px' }} 
                onChange={(e) => setFile(e.target.files[0])}
                className="form-control" />
              </div>
              <div className="form-group form-button">
                <input type="submit" name="signup" id="signup" className="form-submit" defaultValue="Submit" />
              </div>
              </div>
            </form>
          </div>
          <div className="signup-image">
            <img
              src={require("../../assets/images/faces/signup-image.jpg")}
              alt="user"
            />  
            <Link to="/login" className="signup-image-link">I am already a member</Link>
          </div>
        </div>
      </div>
    </section>
    </div>
  );
}
export default CompanyReg;
