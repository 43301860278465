import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

function EditCompanyProfile() {
  const user = Cookies.get("role");
  const navigate = useNavigate();
  var is_company = false;
  if (user === "Company") {
    is_company = true;
  }

  const [personalData, setPersonalData] = useState([
    {
      companyLogo: false,
      firstName: "",
      lastName: "",
      email: "",
      companyContact: "",
    },
  ]);

  const [file, setFile] = useState(null);

  const [companyData, setCompanyData] = useState([
    {
      businessName: "",
      companyName: "",
      companyType: "",
      industry: "",
      companyEmail: "",
      panNumber: "",
      gstType: "",
      gstNo: "",
      paymentTerm: "",
      endDate: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
    },
  ]);

  const ID = Cookies.get("user_id");
  const getProfileDetails = () => {
    axios
      .get(`${config.base_url}/get_profile_data/${ID}/`)
      .then((res) => {
        console.log(res)
        if (res.data.status) {
          const pers = res.data.personalData;
          const cmp = res.data.companyData;
          if (pers.companyLogo) {
            var logoUrl = `${config.base_url}${pers.companyLogo}`;
          }
          const p = {
            companyLogo: logoUrl,
            firstName: pers.firstName,
            lastName: pers.lastName,
            email: pers.email,
            companyContact: pers.companyContact,
          };
          const c = {
            businessName: cmp.businessName,
            companyName: cmp.companyName,
            companyCode: cmp.companyCode,
            companyType: cmp.companyType,
            industry: cmp.industry,
            companyEmail: cmp.companyEmail,
            panNumber: cmp.panNumber,
            gstType: cmp.gstType,
            gstNo: cmp.gstNo,
            paymentTerm: cmp.paymentTerm,
            address: cmp.address,
            city: cmp.city,
            country: cmp.country,
            state: cmp.state,
            pincode: cmp.pincode,
          };
          setPersonalData(p);
          setCompanyData(c);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  const handlePersonalDataChange = (e) => {
    setPersonalData({
      ...personalData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCompanyDataChange = (e) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    });
  };

  function validatePan() {
    var panVal = document.getElementById("pannum").value;
    var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    var pannumElement = document.getElementById("pannum");

    if (regpan.test(panVal)) {
      pannumElement.style.border = "2px solid green";
    } else {
      pannumElement.style.border = "2px solid red";
    }
  }

  function validateGST() {
    var gstVal = document.getElementById("gstnum").value;
    var regGST = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
    var gstnumElement = document.getElementById("gstnum");

    if (regGST.test(gstVal)) {
      gstnumElement.style.border = "2px solid green";
    } else {
      gstnumElement.style.border = "2px solid red";
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Id", ID);
    formData.append("first_name", personalData.firstName);
    formData.append("last_name", personalData.lastName);
    formData.append("email", personalData.email);
    formData.append("contact", personalData.companyContact);
    formData.append("company_name", companyData.companyName);
    formData.append("address", companyData.address);
    formData.append("Company_code", companyData.companyCode);
    formData.append("city", companyData.city);
    formData.append("state", companyData.state);
    formData.append("country", companyData.country);
    formData.append("pincode", companyData.pincode);
    formData.append("business_name", companyData.businessName);
    formData.append("industry", companyData.industry);
    formData.append("company_type", companyData.companyType);
    formData.append("pan_number", companyData.panNumber);
    formData.append("gst_type", companyData.gstType);
    formData.append("gst_no", companyData.gstNo);
    if (file) {
      formData.append("profile_pic", file);
    }

    axios
      .put(`${config.base_url}/edit_company_profile/`, formData)
      .then((res) => {
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Profile Updated",
          });
          navigate("/company_profile");
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const [gstType, setGstType] = useState("");
  const handleGstTypeSubmit = (e) => {
    e.preventDefault();

    const data = {
      ID: ID,
      gsttype: gstType,
    };

    axios
      .post(`${config.base_url}/edit_gsttype/`, data)
      .then((res) => {
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: `${res.data.message}`,
          });
          document.getElementById('closeGstTypeModal').click();
          getProfileDetails();
        }
      })
      .catch((err) => {
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961" }}
        >

      <style>
      {`
      .editbtn {
          background-color: rgb(218, 164, 48);
          color: azure;
          padding: 10px;
          width: 200px;
          border-radius: 20px;
          justify-content: center;
      }
        .editbtn {
          padding: 1;
          justify-content: center;
          height: auto;
      }
      `}
      </style>
      <br></br><br></br>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="card radius-15" style={{borderRadius: '20px',}}>
                <div className="card-body" style={{borderRadius: '20px', background: "#8290c7" }}>
                  <div className="card-title">
                    <form
                      onSubmit={handleSubmit}
                      encType="multipart/form-data"
                      className="px-1"
                    >
                      <center>
                        <h3 className="mb-0" style={{ color: 'rgb(218, 164, 48)' }}>
                          MY PROFILE
                        </h3>

                        {personalData.companyLogo ? (
                          <img
                            src={personalData.companyLogo}
                            className="img img-fluid m-3"
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <img
                            src={require("../../assets/images/faces/default.png")}
                            className="img img-fluid m-3"
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "50%",
                            }}
                          />
                        )}

                        <div className="row d-flex justify-content-center">
                          <input
                            type="file"
                            name="img"
                            className="form-control w-50"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                            accept="image/*"
                            onChange={(e) => setFile(e.target.files[0])}
                          />
                        </div>
                      </center>
                      <hr className="text-white" />
                      
                      <center>
                       <h4 className="my-4 mx-1 w-100" style={{ color: 'rgb(218, 164, 48)' }}>Personal Details</h4>
                      </center>
                      
                      <div className="row my-3 mx-1 w-100">
                        <div className="col-md-6">
                          <label1 htmlFor="first_name" style={{ color: 'white' }}>First Name:</label1>
                          <input
                            type="text"
                            name="firstName"
                            id="first_name"
                            value={personalData.firstName}
                            onChange={handlePersonalDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <label1 htmlFor="last_name" style={{ color: 'white' }}>Last Name:</label1>
                          <input
                            type="text"
                            name="lastName"
                            id="last_name"
                            value={personalData.lastName}
                            onChange={handlePersonalDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row my-3 mx-1 w-100">
                        <div className="col-md-6">
                          <label1 htmlFor="email" style={{ color: 'white' }}>Email:</label1>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={personalData.email}
                            onChange={handlePersonalDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <label1 htmlFor="email" style={{ color: 'white' }}>Contact Number:</label1>
                          <input
                            type="text"
                            name="companyContact"
                            id="contact"
                            value={personalData.companyContact}
                            onChange={handlePersonalDataChange}
                            pattern="[0-9]{10}"
                            title="Phone Number should contain 10 digits."
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                            required
                          />
                        </div>
                      </div>
                      <div className="row m-3">
                        <div className="col-md-6"></div>
                      </div>

                      <center>
                        <h4 className="my-4 mx-1 w-100" style={{ color: 'rgb(218, 164, 48)' }}>Company Details</h4>
                      </center>
                      
                      <div className="row my-3 mx-1 w-100">
                        <div className="col-md-6">
                          <label1 htmlFor="cname" style={{ color: 'white' }}>Company Name:</label1>
                          <input
                            type="text"
                            name="companyName"
                            id="cname"
                            value={companyData.companyName}
                            onChange={handleCompanyDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <label1 htmlFor="ccode" style={{ color: 'white' }}>Company code:</label1>
                          <input
                            type="text"
                            name="code"
                            id="ccode"
                            value={companyData.companyCode}
                            onChange={handleCompanyDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                            readonly
                          />
                        </div>
                      </div>
                      <div className="row my-3 mx-1 w-100">
                      <div className="col-md-6">
                        <label1 htmlFor="caddress" style={{ color: 'white' }}>Company Address:</label1>
                        <input
                          type="text"
                          name="address"
                          id="caddress"
                          value={companyData.address}
                          onChange={handleCompanyDataChange}
                          className="form-control"
                          style={{
                            backgroundColor: 'transparent',
                            color: "black",
                          }}
                        />
                        </div>
                        <div className="col-md-6">
                          <label1 htmlFor="city" style={{ color: 'white' }}>City:</label1>
                          <input
                            type="text"
                            name="city"
                            id="city"
                            value={companyData.city}
                            onChange={handleCompanyDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row my-3 mx-1 w-100">
                        <div className="col-md-6">
                          <div style={{ color: 'white' }}>State:</div>
                          <select
                            name="state"
                            id="state"
                            value={companyData.state}
                            onChange={handleCompanyDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          >
                            <option value="" disabled>Choose State</option>
                            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                            <option value="Daman and Diu">Daman and Diu</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                            <option value="Other Territory">Other Territory</option>
                          </select>
                        </div>

                      
                        <div className="col-md-6">
                          <label1 htmlFor="country" style={{ color: 'white' }}>Country:</label1>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            value={companyData.country}
                            onChange={handleCompanyDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                      </div>

                      <div className="row my-3 mx-1 w-100">
                        <div className="col-md-6">
                          <label1 htmlFor="pincode" style={{ color: 'white' }}>Pincode:</label1>
                          <input
                            type="text"
                            name="pincode"
                            id="pincode"
                            value={companyData.pincode}
                            onChange={handleCompanyDataChange}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                        <div className="col-md-6">
                          <label1 htmlFor="ctype" style={{ color: 'white' }}>Pan Number:</label1>
                          <input
                            type="text"
                            name="panNumber"
                            id="pannum"
                            value={companyData.panNumber}
                            onChange={handleCompanyDataChange}
                            onInput={validatePan}
                            className="form-control"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                          />
                        </div>
                      </div>
                      <div className="row my-3 mx-1 w-100 align-items-center">
                        <div className="col-md-6">
                          <label1 for="industry" style={{ color: 'white' }}>GST Type:</label1>
                            <div className="d-flex">
                            <input
                              type="text"
                              className="form-control"
                              name="gstType"
                              id="gsttype"
                              style={{
                                backgroundColor: 'transparent',
                                color: "black",
                              }}
                              value={companyData.gstType}
                            />
                            <button
                              type="button"
                              style={{
                                borderRadius: "0px 10px 10px 0px",
                                margin: "0",
                              }}
                              title="change Payment Term"
                              typeof="button"
                              className="btn btn-secondary"
                              data-toggle="modal"
                              data-target="#gstchange"
                            >
                              <i className="fa fa-edit"></i>
                            </button>

                            </div>
                        </div>
                        <div className="col-md-6">
                          {companyData.gstType ==
                            "Registered Business - Regular" ||
                          companyData.gstType ==
                            "Registered Business - Composition" ? (
                            <>
                              <label1 for="ctype" style={{ color: 'white' }}>GST Number:</label1>
                              {companyData.gstNo != "" || companyData.gstNo != null ? (
                                <input
                                  type="text"
                                  name="gstNo"
                                  id="gstnum"
                                  value={companyData.gstNo}
                                  onChange={handleCompanyDataChange}
                                  onInput={validateGST}
                                  className="form-control "
                                  style={{
                                    backgroundColor: 'transparent',
                                    color: "black",
                                  }}
                                  pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                                  title="GST Number should be in the format '11AAAAA1111A1ZA'"
                                  required
                                />
                              ) : (
                                <input
                                  type="text"
                                  name="gstNo"
                                  id="gstnum"
                                  onChange={handleCompanyDataChange}
                                  placeholder="Enter your GST number*"
                                  onInput={validateGST}
                                  className="form-control "
                                  style={{
                                    backgroundColor: 'transparent',
                                    color: "black",
                                  }}
                                  pattern="[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}"
                                  title="GST Number should be in the format '11AAAAA1111A1ZA'"
                                  required
                                />
                              )}
                            </>
                          ) : null}
                        </div>
                      </div>
                      
                      <center className="w-100">
                        <button
                          className="btn btn-outline-secondary px-5 mt-4 editbtn"
                          type="submit"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Save Changes
                        </button>
                      </center>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- Modal for change gst type--> */}
            <div
              className="modal fade"
              id="gstchange"
              tabindex="-1"
              aria-label1ledby="exampleModallabel1"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div
                  className="modal-content shadow p-3 rounded"
                  style={{
                    backgroundColor: "rgb(209 209 209)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                >
                  <div className="modal-body">
                    <form
                      onSubmit={handleGstTypeSubmit}
                      className="w-100 px-0"
                    >
                      <div className="row mt-3 w-100">
                        <h5 className="text-secondary w-100 font-weight-bold">GST Type Change</h5>
                      </div>

                      <div className="row mt-3 w-100">
                        <div className="col-md-3">
                          <h6 className="text-secondary mt-2">Type*</h6>
                        </div>
                        <div className="col-md-9">
                          <select
                            className="form-control"
                            name="gsttype"
                            onChange={(e) => setGstType(e.target.value)}
                            id="gsttype"
                            style={{
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                            required
                          >
                            <option
                              value=""
                              style={{
                                backgroundColor: 'transparent',
                                color: "black",
                              }}
                            >
                              Select
                            </option>
                            <option
                              value="Registered Business - Regular"
                              style={{
                                backgroundColor: 'transparent',
                                color: "black",
                              }}
                            >
                              Registered Business - Regular
                              <span>
                                <i>(Business that is registered under gst)</i>
                              </span>
                            </option>
                            <option
                              value="Registered Business - Composition"
                              style={{
                                backgroundColor: 'transparent',
                                color: "black",
                              }}
                            >
                              Registered Business - Composition (Business that
                              is registered under composition scheme in gst)
                            </option>
                            <option
                              value="unregistered Business"
                              style={{
                                backgroundColor: 'transparent',
                                color: "black",
                              }}
                            >
                              Unregistered Business (Business that has not been
                              registered under gst)
                            </option>
                            <option
                              value="Overseas"
                              style={{
                                backgroundColor: 'transparent',
                                color: "black",
                              }}
                            >
                              Overseas (Import/Export of supply outside india)
                            </option>
                            <option
                              value="Consumer"
                              style={{
                                backgroundColor: 'transparent',
                                color: "black",
                              }}
                            >
                              Consumer
                            </option>
                          </select>
                          <input
                            type="text"
                            name="gstno"
                            id="gstno"
                            placeholder="GST Number"
                            className="gstno form-control d-none mt-2 text-white"
                            style={{
                              border: "1px solid rgba(255, 255, 255, 0.3)",
                              backgroundColor: 'transparent',
                              color: "black",
                            }}
                            pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                            title="GST Number should be in the format '11AAAAA1111A1ZA'"
                          />
                        </div>
                      </div>

                      <div className="modal-footer mt-3 d-flex justify-content-end w-100">
                        <button
                          type="reset"
                          style={{ width: "70px", height: "30px" }}
                          className="btn btn-sm btn-outline-danger"
                          data-dismiss="modal"
                          id="closeGstTypeModal"
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          style={{ width: "70px", height: "30px" }}
                          className="btn btn-sm btn-success"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCompanyProfile;
