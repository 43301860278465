import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import 'bootstrap/dist/css/bootstrap.min.css';


function CompanyProfile() {
  const user = Cookies.get("role");
  let is_company = false;
  if (user === "Company") {
    is_company = true;
  }

  const [personalData, setPersonalData] = useState([
    {
      companyLogo: false,
      userImage: false,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      companyContact: "",
      userContact: "",
    },
  ]);

  const [companyData, setCompanyData] = useState([
    {
      companyName: "",
      companyCode: "",
      companyEmail: "",
      panNumber: "",
      gstType: "",
      gstNo: "",
      address: "",
      city: "",
      state: "",
      country:"",
      pincode: "",
    },
  ]);


  const ID = Cookies.get("user_id");
  const getProfileDetails = () => {
    axios
      .get(`${config.base_url}/get_profile_data/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const pers = res.data.personalData;
          const cmp = res.data.companyData;
          if (pers.companyLogo) {
            var logoUrl = `${config.base_url}${pers.companyLogo}`;
          }
          if (pers.userImage) {
            var imgUrl = `${config.base_url}${pers.userImage}`;
          }
          const p = {
            companyLogo: logoUrl,
            userImage: imgUrl,
            firstName: pers.firstName,
            lastName: pers.lastName,
            email: pers.email,
            username: pers.username,
            companyContact: pers.companyContact,
            userContact: pers.userContact,
          };
          const c = {
            companyName: cmp.companyName,
            companyCode: cmp.companyCode,
            companyEmail: cmp.companyEmail,
            panNumber: cmp.panNumber,
            gstType: cmp.gstType,
            gstNo: cmp.gstNo,
            address: cmp.address,
            city: cmp.city,
            state: cmp.state,
            country:cmp.country,
            pincode: cmp.pincode,
          };
          setPersonalData(p);
          setCompanyData(c);
        }
      })
      .catch((err) => {
        console.log("ERROR==", err);
      });
  };
  const navigate = useNavigate();
  

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  useEffect(() => {
    getProfileDetails();
  }, []);
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961" }}
        >

      <style>
      {`
      .editbtn {
          background-color: rgb(218, 164, 48);
          color: azure;
          padding: 10px;
          width: 200px;
          border-radius: 20px;
          justify-content: center;
      }
        .editbtn {
          padding: 1;
          justify-content: center;
          height: auto;
      }
      .profimg {
          width: 100px;
          height: 100px;
          border-radius: 80%;
      }
      .profimg {
              width: 80px;
              height: 80px; /* Make profile image smaller for mobile */
          }
      h3 {
          text-align: center;
          text-transform: capitalize;
          color: azure;
      }
      `}
      </style>
      <br></br><br></br>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="card radius-15" style={{borderRadius: '20px',}}>
                <div className="card-body" style={{borderRadius: '20px', background: "#8290c7" }}>
                  <div className="card-title">

                  <h3 className="text-center mt-5">
                      <b>{personalData.firstName} {personalData.lastName}</b>
                  </h3>

                  <div className="my-5 text-center">
                    {is_company ? (
                      personalData.companyLogo ? (
                        <img src={personalData.companyLogo} className="rounded profimg" alt="Company Profile" />
                      ) : (
                        <img src={require("../../assets/images/faces/default.png")} className="rounded profimg" alt="user" />
                      )
                    ) : (
                      personalData.userImage ? (
                        <img src={personalData.userImage} className="rounded profimg" alt="User Profile" />
                      ) : (
                        <img src={require("../../assets/images/faces/default.png")} className="rounded profimg" alt="user" />
                      )
                    )}
                  </div>
                    
                  <div style={{ width: '100%' }}>
                  <table className="table table-responsive" style={{ backgroundColor: 'white', width: '100%' }}>
                    <tbody style={{ border: 'none' }}>
                      <tr className="personal">
                        <td style={{ borderBottom: 'none', borderTop: 'none' }}>
                          <h4 style={{ color: 'rgb(218, 164, 48)' }}>
                            <em><u>Personal Details</u></em>
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td>Username</td>
                        <td>: {personalData.username}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>: {personalData.email}</td>
                      </tr>
                      {is_company ? (
                        <tr>
                          <td>Contact Number</td>
                          <td>: {personalData.companyContact}</td>
                        </tr>
                      ) : (
                        <tr>
                          <td>Contact Number</td>
                          <td>: {personalData.userContact}</td>
                        </tr>
                      )}

                      {is_company ? (
                        <>
                          <tr className="companydet mt-5">
                            <td style={{ borderBottom: 'none', borderTop: 'none' }}>
                              <h4 style={{ color: 'rgb(218, 164, 48)' }}>
                                <em><u>Company Details</u></em>
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td>Company Name</td>
                            <td>: {companyData.companyName}</td>
                          </tr>
                          <tr>
                            <td>Company Code</td>
                            <td>: {companyData.companyCode}</td>
                          </tr>
                          <tr>
                            <td>Address</td>
                            <td>: {companyData.address}</td>
                          </tr>
                          <tr>
                            <td>City</td>
                            <td>: {companyData.city}</td>
                          </tr>
                          <tr>
                            <td>State</td>
                            <td>: {companyData.state}</td>
                          </tr>
                          <tr>
                            <td>Country</td>
                            <td>: {companyData.country}</td>
                          </tr>
                          <tr>
                            <td>Pincode</td>
                            <td>: {companyData.pincode}</td>
                          </tr>
                          <tr>
                            <td>GST Type</td>
                            <td>: {companyData.gstType}</td>
                          </tr>
                          <tr>
                            <td>GST Number</td>
                            <td>: {companyData.gstNo}</td>
                          </tr>
                          <tr>
                            <td>PAN Number</td>
                            <td>: {companyData.panNumber}</td>
                          </tr>
                        </>
                      ) : null}
                    </tbody>
                  </table>

                  <center>
                  <Link to={is_company ? "/edit_company_profile" : "/edit_staff_profile"}>
                      <button className="btn btn-outline-secondary h-100 editbtn" type="button">
                      Edit Profile
                      </button>
                  </Link>
                  </center>

                  <style jsx>{`
                    @media (max-width: 768px) {
                      table.table-responsive td {
                        padding: 8px;
                        font-size: 14px;
                      }
                      h4 {
                        font-size: 18px;
                      }
                    }
                    @media (max-width: 576px) {
                      table.table-responsive td {
                        padding: 6px;
                        font-size: 12px;
                      }
                      h4 {
                        font-size: 16px;
                      }
                    }
                  `}</style>
                </div>


                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyProfile;
